import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { hash } from '@ember/helper';
import { not } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import ErrorModal from 'tio-employee/components/auth/reset-password/error-modal';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import resetPasswordFormSchema from 'tio-common/validation-schema/forms/reset-password-form';
import RouteTemplate from 'ember-route-template';
import TioButton from 'tio-common/components/tio/button';
import TioEmbedVue from 'tio-common/components/tio/embed-vue';
import type NotificationsService from '@frontile/notifications/services/notifications';
import type PreRegisterService from '../../services/pre-register.ts';
import type ResetPasswordController from 'tio-employee/controllers/reset-password';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
interface ResetPasswordRouteComponentSignature {
    Args: {
        controller: ResetPasswordController;
        model?: {
            error: string;
        };
    };
}
let ResetPasswordRoute = class ResetPasswordRoute extends Component<ResetPasswordRouteComponentSignature> {
    @service
    store: StoreService;
    @service
    router: RouterService;
    @service
    preRegister: PreRegisterService;
    @service
    notifications: NotificationsService;
    @tracked
    password = '';
    @tracked
    passwordConfirmation = '';
    @tracked
    isLoading = false;
    get isTokenInvalid() {
        return !!this.args.model?.error;
    }
    get isEmberEnabled() {
        return this.args.controller['ember-reset-password'] === '1';
    }
    get passwordMinLength() {
        return this.preRegister.settings?.security?.passwordMinLength || 8;
    }
    get formModel() {
        return {
            password: this.password,
            passwordConfirmation: this.passwordConfirmation
        };
    }
    get formValidationErrors() {
        try {
            resetPasswordFormSchema?.validateSync?.(this.formModel, {
                abortEarly: false,
                context: {
                    passwordMinLength: this.passwordMinLength
                }
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get isSubmitDisabled() {
        return this.formValidationErrors.length;
    }
    resetPassword = dropTask(async ()=>{
        try {
            await this.store.adapterFor('login').resetPassword({
                password: this.password,
                passwordConfirmation: this.passwordConfirmation,
                token: this.args.controller.token
            });
            this.router.transitionTo('reset-password.confirmation');
        } catch (e1) {
            const errorMessage1 = e1?.errors?.length ? e1.errors[0] : 'An Error Occurred';
            this.notifications.add(errorMessage1, {
                appearance: 'error'
            });
        }
        this.isLoading = false;
    });
    @action
    submitResetPassword() {
        this.isLoading = true;
        this.resetPassword.perform();
    }
    @action
    onSubmitModal() {
        this.router.transitionTo('login');
    }
    static{
        template(`
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <TioEmbedVue
        @route="/reset-password"
        @query={{hash token=@controller.token}}
        @disabled={{this.isEmberEnabled}}
      >
        <EmailLookupWrapper @disabled={{not this.isEmberEnabled}} @route="reset-password">
          <h1 class="text-xl my-4 text-center">{{t "login.reset_password_title"}}</h1>
          <p class="mb-4 text-center">{{t "login.reset_password_body"}}</p>
          <form {{on "submit" this.submitResetPassword}}>
            <div class="mb-10">
              <FormInput
                @label={{t "login.new_password"}}
                @value={{this.password}}
                @onInput={{fn (mut this.password)}}
                @errors={{errorsForField "password" schemaErrors=this.formValidationErrors}}
                @type="password"
              />
            </div>
            <div class="mb-10">
              <FormInput
                @label={{t "login.confirm_new_password"}}
                @value={{this.passwordConfirmation}}
                @onInput={{fn (mut this.passwordConfirmation)}}
                @errors={{errorsForField
                  "passwordConfirmation"
                  schemaErrors=this.formValidationErrors
                }}
                @type="password"
              />
            </div>
            <TioButton
              @loading={{this.isLoading}}
              @onClick={{this.submitResetPassword}}
              @disabled={{this.isSubmitDisabled}}
              class="w-36 mx-auto mb-6"
            >
              {{t "submit"}}
            </TioButton>
            <div data-test-login-here class="mb-6 text-md text-center">
              {{t "login.need_to_register"}}
              <a class="underline text-ocean-600" href="/register">
                {{t "login.register_new_account"}}
              </a>
            </div>
          </form>
          <ErrorModal @isOpen={{this.isTokenInvalid}} @onSubmit={{this.onSubmitModal}} />
        </EmailLookupWrapper>
      </TioEmbedVue>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ResetPasswordRoute);
