import { template } from "@ember/template-compiler";
import { Input, Textarea, Select } from 'tio-ui/components/forms';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { fn } from '@ember/helper';
import { A } from '@ember/array';
import { eq, and } from 'ember-truth-helpers';
import { inputsForFields } from 'tio-common/utils/data/inputs-for-fields';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { currencyStringToNumber } from 'tio-common/utils/format';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import inputmask from 'tio-common/modifiers/inputmask';
import Component from '@glimmer/component';
import Drawer from 'tio-ui/components/drawer';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type Store from '@ember-data/store';
interface AddEducationDrawerSignature {
    Args: {
        isOpen: boolean;
        onClose: () => void;
        application: TasApplication;
        course: TasCourse | null;
    };
    Element: HTMLDivElement;
}
export interface BuiltInput {
    id: string;
    name: keyof TasCourse['fields'];
    label: string;
    type: string;
    inputType: string;
    required: boolean;
    values: (string | number)[];
    errors: string[];
    options: {
        value: string;
        label: string;
    }[];
    selectionMode: 'single' | 'multiple';
    custom: boolean;
}
export default class AddEducationDrawerComponent extends Component<AddEducationDrawerSignature> {
    @service
    store: typeof Store;
    @tracked
    inputs: BuiltInput[] = A([]);
    @tracked
    selectedKeys: string[] = [];
    programTemplate: TasProgramTemplate;
    static createInputs(application1: TasApplication): BuiltInput[] {
        const builtInputs1 = [];
        const programTemplate1 = application1.tasProgramInstance.tasProgramTemplate;
        for(const field1 in programTemplate1.courseFieldsHash){
            // Merge the field data with input data
            let mergedInput1 = Object.assign(inputsForFields[field1 as keyof typeof inputsForFields], programTemplate1.fields[field1 as keyof typeof programTemplate.fields]);
            builtInputs1.push(mergedInput1);
        }
        return builtInputs1 as unknown as BuiltInput[];
    }
    constructor(owner1: unknown, args1: AddEducationDrawerSignature['Args']){
        super(owner1, args1);
        const { application: application1 } = args1;
        this.inputs = AddEducationDrawerComponent.createInputs(application1);
        this.programTemplate = application1.tasProgramInstance.tasProgramTemplate;
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    get courseFields(): TasCourse['fields'] {
        return this.args.course!.fields;
    }
    @action
    addEducation() {
        this.args.course!.save();
        this.args.onClose();
    }
    @action
    getValue(values1: (string | number)[]): string {
        return values1[0]?.toString() || '';
    }
    @action
    setValue(input1: BuiltInput, value1: string | number) {
        if (input1.type === 'currency') {
            value1 = currencyStringToNumber(value1 as string);
        }
        if (input1.type === 'number') {
            // course credits can be halves ex: 2.5
            value1 = parseFloat(value1 as string);
        }
        if (input1.custom === true) {
            setSingleValueForTasField(input1.name, value1, this.courseFields);
        }
        input1.values.push(value1);
        setSingleValueForTasField(input1.name, value1, this.courseFields);
    }
    static{
        template(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}}>
      <div class="p-6 bg-gray-50">
        <h2 class="text-gray-600 font-semibold">{{t
            "add_education_drawer.add_education_information"
          }}</h2>
        <p class="text-gray-400 text-md">{{t "add_education_drawer.fill_out_education_below"}}</p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">{{t "add_education_drawer.education_information"}}</h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      {{#each this.inputs as |input|}}

        <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
          <label for={{input.id}} class="font-semibold text-gray-600">
            {{input.label}}
            {{#if input.required}}
              <span class="text-error-400">*</span>
            {{/if}}
          </label>
          {{! regular input with type currency }}
          {{#if (and (eq input.inputType "input") (eq input.type "currency"))}}
            <Input
              id={{input.id}}
              @classes={{this.inputClasses}}
              @value="{{this.getValue input.values}}"
              @onChange={{fn this.setValue input}}
              {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
              data-test-education-drawer-input-currency
            />
          {{/if}}
          {{! regular input with type string }}
          {{#if (and (eq input.inputType "input") (eq input.type "string"))}}
            <Input
              id={{input.id}}
              @classes={{this.inputClasses}}
              @value="{{this.getValue input.values}}"
              @onChange={{fn this.setValue input}}
              @errors={{input.errors}}
              data-test-education-drawer-input-string
            />
          {{/if}}
          {{! regular input with type number }}
          {{#if (and (eq input.inputType "input") (eq input.type "number"))}}
            <Input
              id={{input.id}}
              @classes={{this.inputClasses}}
              @value="{{this.getValue input.values}}"
              @onChange={{fn this.setValue input}}
              @errors={{input.errors}}
              data-test-education-drawer-input-number
            />
          {{/if}}
          {{#if (eq input.inputType "textarea")}}
            <Textarea
              @id={{input.id}}
              @classes={{this.inputClasses}}
              @value="{{this.getValue input.values}}"
              @onChange={{fn this.setValue input}}
              data-test-education-drawer-textarea
            />
          {{/if}}
          {{#if (eq input.inputType "radio")}}
            <FormRadioGroup
              @value={{this.getValue input.values}}
              {{! @glint-expect-error }}
              @onChange={{fn this.setValue input}}
              {{!-- @errors={{errorsForField "employmentStatus" schemaErrors=this.formValidationErrors}} --}}
              @containerClass="py-4"
              as |Radio|
            >
              {{#each input.options as |option|}}
                {{! @glint-expect-error }}
                <Radio @value={{option.value}} @label={{option.label}} />
              {{/each}}
            </FormRadioGroup>
          {{/if}}
          {{#if (eq input.inputType "select")}}
            <Select
              @id={{input.id}}
              @classes={{this.inputClasses}}
              @items={{input.options}}
              @selectionMode={{input.selectionMode}}
              @onAction={{fn this.setValue input}}
              data-test-education-drawer-select
            >
              <:item as |o|>
                <o.Item @key={{o.item.value}} @intent="default" @appearance="faded">
                  {{o.item.value}}
                </o.Item>
              </:item>
            </Select>
          {{/if}}
        </div>

      {{/each}}
      <div class="mt-8 flex justify-end">
        <button
          type="button"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-gray-800 focus:outline-none bg-white rounded-lg hover:bg-gray-50 hover:border hover:border-black"
          {{on "click" @onClose}}
        >
          {{t "cancel"}}
        </button>
        <button
          type="submit"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
          {{on "click" this.addEducation}}
        >
          {{t "save"}}
        </button>
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
